/* unvisited link */
a.my_link:link {
  color: #007EBA;
  text-decoration: none;
}

/* visited link */
a.my_link:visited {
  color: #007EBA;
  text-decoration: none;
}

/* mouse over link */
a.my_link:hover {
  color: #ec7211;
  text-decoration: none;
}

/* selected link */
a.my_link:active {
  color: #007EBA;
  text-decoration: none;
}

pre code {
  background-color: #eee;
  border: 1px solid #999;
  display: block;
  padding: 20px;
}